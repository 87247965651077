
import { defineComponent } from "vue";

export default defineComponent({
  name: "Article",
  props: ["headers", "texts"],
  data() {
  return {
    header: this.headers,
    text: this.texts,
  }
}
});
