
// import Head from "@/components/Head.vue"
import Article from "@/components/Article.vue"
import { ref } from 'vue'
// import QrcodeVue, { Level, RenderAs } from 'qrcode.vue'
// import Footer from "@/components/Footer.vue";

export default {
  name: "Novinky",
  components: {
    // Head,
    Article,
    // QrcodeVue
    // Footer,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to: any, from: AnalyserNode) {
        document.title = "MasterGames | Store";
      },
    },
  },
  setup(){
    const UserID = ref<string>('无法自动获取UserID')
    // const money = ref<string>('10')
    // const message = ref<string>('UUID')
    // const value = ref<string>(`alipays://platformapi/startapp?appId=20000123&actionType=scan&biz_data={"s": "money","u": "2088712822863388","a": "${money.value}","m":"${message.value}"}`)
    // const level = ref<Level>('M')
    // const renderAs = ref<RenderAs>('svg')
    // Function to parse the query string and return it as an object
    function getQueryParams(): { [key: string]: string } {
      const queryString: string = window.location.search.slice(1) || window.location.hash.split("?")[1]; // Remove the '?' character
      // console.log(queryString);
      if (queryString === undefined) return {};
      const paramsArray: string[] = queryString.split('&');
      const queryParams: { [key: string]: string } = {};

      paramsArray.forEach(function (param) {
        const paramParts: string[] = param.split('=');
        const paramName: string = decodeURIComponent(paramParts[0]);
        const paramValue: string = decodeURIComponent(paramParts[1]);
        queryParams[paramName] = paramValue;
      });

      return queryParams;
    }
    // Example usage:
    var queryParams = getQueryParams();
    console.log(queryParams); // This will print the query string parameters as an object
    if (queryParams.userid) {
      UserID.value = queryParams.userid;
    }

    // function generateQRCode() {
    //   value.value = `alipays://platformapi/startapp?appId=20000123&actionType=scan&biz_data={"s": "money","u": "2088712822863388","a": "${money.value}","m":"${message.value}"}`
    // }
    
    // watch(money, (newValue, oldValue) => {
    //   // Remove any non-numeric and non-decimal characters
    //   let value = newValue.replace(/[^0-9.]/g, '');
    //   // Limit to two decimal places
    //   const parts = value.split('.');
    //   if (parts.length > 1) {
    //     value = parts[0] + '.' + parts[1].slice(0, 2);
    //   }
    //   // Update the inputValue with the cleaned and formatted value
    //   money.value = value;
    //   generateQRCode();
    // });

    return { UserID };
  }
};




